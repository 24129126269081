<template>
    <v-dialog v-model="dialog" persistent max-width="540">        
            <ValidationObserver v-slot="{ invalid }" ref="form">
              <v-card :key="key">
                <v-app-bar flat color="primary">
                  <v-toolbar-title class="text-h6 white--text pl-0">
                    {{ $t("print_remote") }}
                  </v-toolbar-title>
                  <v-spacer></v-spacer>
                  <v-btn color="white" icon @click="$emit('close')" :title="$t('Cancel')">
                    <v-icon>mdi-close</v-icon>
                  </v-btn>
                </v-app-bar>
                <v-card-text>
                <v-row class="pa-4 pt-2">
                  <v-col cols="12" sm="12" class="pb-0">
                   <h4 class="font-weight-medium">{{ $t('select_print_remote')}}:                    </h4>
                  </v-col>
                  <v-col cols="12" sm="12" class="py-0 mt-2">
                    <v-btn color="primary" block large :disabled="invalid" @click="printRemote(false)" :title="$t('print_remote_missing')" class="mb-4" v-if="order && order.getTotalItemsPrintRemote() > 0">
                    {{ $t("print_remote_missing") }}
                  </v-btn> 

                    <v-btn color="success" block large  @click="printRemote(true)" :title="$t('print_remote_all')">
                    {{ $t("print_remote_all") }}
                  </v-btn> 
                                 
                  </v-col>
                </v-row>
              </v-card-text>
                <v-divider></v-divider>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn dark color="primary" outlined @click="$emit('close')" :title="$t('Cancel')">
                    {{ $t("Cancel") }}
                  </v-btn>
                </v-card-actions>
              </v-card>
            </ValidationObserver>
    </v-dialog>
</template>
<style>
.bg_modal{
  background: linear-gradient(rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3)) 0% 0% / cover no-repeat;
}
.v-input--radio-group.v-input--radio-group--row .v-radio.v-item--active{
  border:none !important;
}
</style>
<script>
import NgTextKeyboard from '../../../gui/NgTextKeyboard.vue';
const DEFAULT_ITEM = { reason: "" };
export default {
  components: { NgTextKeyboard },
  name: "CartPrintRemote",
  data: () => ({
    searched: false,
    key: 0,
    tip: 0,
    automatic: true
  }),
  props: {
    dialog: {
      type: Boolean,
      default: false,
    },
    order: Object 
  },
  watch: {
    "$store.getters.orderChanged": function () {
      this.tip = this.$pos.getCurrentOrder().getPriceTip(); 
    }
  },
  mounted() {
    this.tip = this.$pos.getCurrentOrder().getPriceTip();
  },
  methods: {
    cancel() {
      this.clear();
      this.searched = false;
      this.key++;
      this.$emit("cancel");
    },
    clear() {
      this.item = JSON.parse(JSON.stringify(DEFAULT_ITEM));
    },
    printRemote(all){     
      this.$emit("cancel");
      console.log('print_all_', all);
      this.$pos.printRemote(this.order, all);
    }
  },
};
</script>