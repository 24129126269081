<template>
  <v-col
    md="5"
    :lg="setColAspect()"
    class="ma-0 pa-0"
    :class="
      is_mobile
        ? 'cart_mobile mobile_height'
        : 'desktop-height d-flex flex-column border-left'
    "
  >
    <v-row v-if="order" no-gutters>
      <v-col cols="12">
        <v-btn-toggle
          v-model="orderType"
          @change="changeOrderType"
          max="1"
          tile
          class="d-flex flex-row orderType"
        >
          <v-btn
            class="flex-grow-1 grey--text text--darken-2"
            :title="$t('in_direct')"
            value="is_direct"
          >
            <v-icon class="me-lg-1" left color="grey darken-2"
              >mdi-cash-register</v-icon
            ><span>{{ $t("in_direct") }}</span>
          </v-btn>
          <v-btn
            class="flex-grow-1 grey--text text--darken-2"
            :title="$t('in_table')"
            value="is_on_table"
            v-if="$store.getters.getGeneralValue('gen_sales_manage_tables')"
          >
            <v-icon class="me-lg-1" left color="grey darken-2"
              >mdi-table-furniture</v-icon
            ><span>{{ $t("in_table") }}</span>
          </v-btn>
          <v-btn
            class="flex-grow-1 grey--text text--darken-2"
            :title="$t('delivery')"
            value="is_domicile"
          >
            <v-icon class="me-lg-1" left color="grey darken-2">mdi-moped</v-icon
            ><span>{{ $t("delivery") }}</span>
          </v-btn>
        </v-btn-toggle>
      </v-col>
    </v-row>

    <v-divider />

    <v-row v-if="order" class="mt-0" ref="optionsOrder" no-gutters>
      <v-col>
        <v-tabs
          v-model="tab"
          background-color="grey lighten-4"
          grow
          icons-and-text
          show-arrows
          centered
        >
          <v-tabs-slider color="grey lighten-2"></v-tabs-slider>

          <v-tab href="#order" :class="orderTabClasses">
            Pedido
            <v-icon :color="orderTabColor">mdi-store</v-icon>
          </v-tab>

          <v-tab href="#coupon" :class="couponTabClasses">
            Cupón
            <v-icon :color="couponTabColor">mdi-tag-multiple</v-icon>
          </v-tab>

          <v-tab href="#tip" :class="tipTabClasses">
            Propina
            <v-icon :color="tipTabColor">mdi-hand-heart-outline</v-icon>
          </v-tab>

          <v-tab href="#invoice" :class="invoiceTabClasses">
            Factura
            <v-icon :color="invoiceTabColor">mdi-file-sign</v-icon>
          </v-tab>
        </v-tabs>
        <v-divider />
        <v-tabs-items v-model="tab">
          <v-tab-item value="order">
            <v-card flat class="grey lighten-5 pa-0 ma-0">
              <v-card-text>
                <v-row>

                  <v-col
                    cols="12"
                    lg="6"
                    :class="$pos.getCurrentOrder().data_customer ? 'pa-0' : ''"
                  >
                    <v-btn
                      class="flex-grow-1 py-5"
                      color="primary"
                      outlined
                      block
                      @click="modalCustomer = true"
                      v-if="!$pos.getCurrentOrder().data_customer"
                      ><v-icon class="me-1">mdi-account-search</v-icon
                      >{{ $t("search_client") }}</v-btn
                    >
                    <v-list v-else class="transparent pa-0">
                      <v-list-item>
                        <v-list-item-icon @click="modalCustomer = true">
                          <v-btn icon>
                            <v-icon color="primary">
                              mdi-account-search
                            </v-icon>
                          </v-btn>
                        </v-list-item-icon>

                        <v-list-item-content @click="modalCustomer = true">
                          <v-list-item-title>{{
                            $pos.getCurrentOrder().getCustomerName()
                          }}</v-list-item-title>
                          <v-list-item-subtitle>{{
                            $pos.getCurrentOrder().getName()
                          }}</v-list-item-subtitle>
                        </v-list-item-content>

                        <v-list-item-icon @click="removeClient()">
                          <v-btn icon>
                            <v-icon color="error">mdi-close</v-icon>
                          </v-btn>
                        </v-list-item-icon>
                      </v-list-item>
                    </v-list>
                  </v-col>

                  <v-col
                    cols="12"
                    lg="6"
                    v-if="order.getIsOnTable()"
                    :class="order.getIsOnTable() ? '' : 'pa-0'"
                  >
                    <div class="flex-grow-1" v-if="order.getNameTable()">
                      <v-list class="transparent pa-0">
                        <v-list-item>
                          <v-list-item-icon @click="modalTables = true">
                            <v-btn icon>
                              <v-icon color="primary">
                                mdi-account-search
                              </v-icon>
                            </v-btn>
                          </v-list-item-icon>

                          <v-list-item-content @click="modalTables = true">
                            <v-list-item-title>{{
                              order.getNameTable()
                            }}</v-list-item-title>
                            <v-list-item-subtitle>{{
                              $t("table")
                            }}</v-list-item-subtitle>
                          </v-list-item-content>

                          <v-list-item-icon
                            @click="
                              $pos.setNameTable($pos.getCurrentOrder(), null)
                            "
                          >
                            <v-btn icon>
                              <v-icon color="error">mdi-close</v-icon>
                            </v-btn>
                          </v-list-item-icon>
                        </v-list-item>
                      </v-list>

                      <v-btn-toggle class="d-flex flex-row">
                        <v-btn
                          class="flex-grow-1 blue--text"
                          color="blue lighten-5"
                          @click="modalCustomer = true"
                          :title="$t('edit')"
                          value="edit"
                          ><v-icon class="me-1" color="blue">mdi-pencil</v-icon
                          >{{ order.getNameTable() }}</v-btn
                        >
                        <v-btn
                          color="red lighten-5"
                          @click="
                            $pos.setNameTable($pos.getCurrentOrder(), null)
                          "
                          :title="$t('remove')"
                          value="remove"
                          ><v-icon color="red">mdi-close</v-icon></v-btn
                        >
                      </v-btn-toggle>
                    </div>
                    <v-btn
                      v-else
                      class="flex-grow-1 py-5"
                      block
                      color="primary"
                      outlined
                      @click="modalTables = true"
                      ><v-icon class="me-1">mdi-table-furniture</v-icon
                      >{{ $t("select_table") }}</v-btn
                    >
                  </v-col>
                  <v-col cols="12" lg="6">
                    <ng-select-options
                      v-model="seller"
                      prepend-inner-icon="mdi-account-tie"
                      :label="$t('Seller')"
                      :items="sellers"
                      @change="changeSeller"
                      :flat="false"
                      :filled="false"
                      dense
                      :outlined="true"
                      hide-details
                    ></ng-select-options>
                  </v-col>
                  <v-col cols="12" v-if="order.getIsDomicile()">
                    <v-row>
                      <v-col cols="12" lg="6">
                        <ng-number-keyboard
                          prepend-inner-icon="mdi-cash-plus"
                          v-model="price_delivery"
                          :label="$t('price_delivery')"
                          @input="changePriceDelivery"
                          :flat="false"
                          :filled="false"
                          dense
                          :outlined="true"
                          hide-details
                          class="mb-3"
                        ></ng-number-keyboard
                      ></v-col>
                      <v-col cols="12" lg="6">
                        <ng-select-options
                          v-model="domiciliary"
                          prepend-inner-icon="mdi-moped"
                          :label="$t('Domiciliary')"
                          :items="domiciliaries"
                          @change="changeDomiciliary"
                          :flat="false"
                          :filled="false"
                          dense
                          :outlined="true"
                          hide-details
                        ></ng-select-options
                      ></v-col>
                    </v-row>
                  </v-col>
                </v-row>
              </v-card-text>

              <v-divider />

              <v-card-text
                class="lighten-5 d-flex flex-row pa-0"
                style="max-height: 45px"
              >
                <v-btn
                  class="flex-grow-1"
                  large
                  tile
                  :title="$t('total_items_current_order')"
                >
                  <v-badge
                    color="grey"
                    overlap
                    top
                    offset-x="10"
                    offset-y="15"
                    :content="'' + $pos.getCurrentOrder().getTotalItems()"
                  >
                    <v-icon color="grey darken-1">mdi-basket-outline</v-icon>
                  </v-badge>
                </v-btn>
                <v-btn
                  class="black--text flex-grow-1"
                  large
                  tile
                  @click="noteOrder()"
                  :title="$t('add_note')"
                >
                  <v-icon color="grey darken-1"
                    >mdi-comment-text-outline</v-icon
                  >
                </v-btn>
                <v-btn
                  class="flex-grow-1"
                  large
                  tile
                  :title="$t('print_screen')"
                  @click="printScreen()"
                  :disabled="$pos.getCurrentOrder().isEmpty()"
                >
                  <v-icon color="grey darken-1">mdi-printer-outline</v-icon>
                </v-btn>
                <v-btn
                  class="flex-grow-1"
                  large
                  tile
                  :title="$t('print_remote')"
                  @click="printRemote()"
                  :disabled="$pos.getCurrentOrder().isEmpty()"
                  v-if="getValueActivePrintRemote()"
                >
                  <v-icon color="grey darken-1">mdi-cloud-print-outline</v-icon>
                </v-btn>
                <v-btn
                  class="red--text red lighten-5 flex-grow-1"
                  large
                  tile
                  :disabled="$pos.getCurrentOrder().isEmpty()"
                  @click="openAnnulOrder()"
                  :title="$t('cancel')"
                >
                  <v-icon color="red">mdi-close-circle-outline</v-icon>
                </v-btn>
              </v-card-text>
            </v-card>
          </v-tab-item>

          <v-tab-item value="coupon">
            <v-card flat color="grey lighten-5">
              <v-card-text class="d-lg-flex flex-row align-center"
                ><span class="me-2"
                  >¿Tienes un cupón de descuento para aplicar?</span
                >
                <div class="my-auto">
                  <v-chip
                    outlined
                    color="green px-5"
                    v-if="haveCoupon()"
                    class="font-weight-regular black--text subtitle-2"
                  >
                    {{ order.getCoupon() }}
                    <v-icon right @click="modalCoupon = true"
                      >mdi-tag-multiple-outline</v-icon
                    >
                    <v-icon
                      color="red"
                      right
                      @click="$pos.setCoupon($pos.getCurrentOrder(), null)"
                      >mdi-close</v-icon
                    >
                  </v-chip>
                  <template v-else>
                    <v-chip
                      outlined
                      color="orange darken-2 px-5"
                      @click="modalCoupon = true"
                      class="font-weight-regular black--text subtitle-2"
                      v-if="
                        $store.getters.getGeneralValue(
                          'gen_sales_activate_coupons'
                        )
                      "
                    >
                      {{ $t("no_coupon") }}
                      <v-icon right>mdi-tag-multiple-outline</v-icon>
                    </v-chip>
                    <v-chip
                      outlined
                      color="orange darken-2 px-5"
                      v-else
                      class="font-weight-regular black--text subtitle-2"
                    >
                      {{ $t("no_coupon") }}
                      <v-icon right>mdi-tag-multiple-outline</v-icon>
                    </v-chip>
                  </template>
                </div>
              </v-card-text>
            </v-card>
          </v-tab-item>

          <v-tab-item value="tip">
            <v-card flat color="grey lighten-5">
              <v-card-text class="d-lg-flex flex-row align-center py-1"
                ><span class="me-2">¿Deseas dejar una propina? (Sí/No)</span>
                <v-switch
                  inset
                  class="py-0"
                  :title="$t('instructions_pos_tip')"
                  prepend-icon="mdi-cash-100"
                  v-model="activeTip"
                  dense
                  :label="$t('tip')"
                  @change="changeTip()"
                ></v-switch>
              </v-card-text>
            </v-card>
          </v-tab-item>

          <v-tab-item value="invoice">
            <v-card flat color="grey lighten-5">
              <v-card-text class="d-lg-flex flex-row align-center py-1">
                <span class="me-2"
                  >¿Desea generar una factura electrónica? (Sí/No)</span
                >
                <v-switch
                  inset
                  class="d-inline-flex font-weight-medium black--text text--black py-0"
                  :title="$t('instructions_pos_invoice_electronic')"
                  prepend-icon="mdi-file-sign"
                  v-model="activeInvoiceElectronic"
                  dense
                  :label="$t('pos_invoice_electronic')"
                  @change="changeInvoiceElectronic()"
                  v-if="
                    $store.getters.getGeneralValue(
                      'gen_billing_invoice_electronically'
                    ) == true
                  "
                ></v-switch
              ></v-card-text>
            </v-card>
          </v-tab-item>
        </v-tabs-items>
      </v-col>
    </v-row>

    <v-divider></v-divider>
    <div
      v-if="order"
      :style="getHeight()"
      class="white pa-0 ma-0"
      :class="is_mobile ? 'overflow-scroll' : ''"
      :key="$store.getters.orderChanged"
    >
      <v-list shaped class="transparent ma-0 pa-0">
        <v-list-item
          v-for="item in order.data_items"
          :key="item.id"
          class="cart-item px-2"
        >
          <CartSectionItem
            :item="item"
            @delete:item="deleteItem"
            @edit:item="$emit('edit:item', $event)"
          >
          </CartSectionItem>
          <!-- <v-divider></v-divider> -->
        </v-list-item>
        <!--{{order.data_items}}-->
      </v-list>
    </div>

    <v-divider />
    <v-row
      v-if="order"
      class="pa-0 ma-0"
      ref="totalsSection"
      id="totals_section"
    >
      <v-col class="pa-0 ma-0">
        <v-simple-table class="grey lighten-5 font-weight-bold">
          <tbody>
            <tr v-if="$store.getters.turn">
              <td colspan="4">
                <div class="d-flex flex-row align-center">
                  <v-icon small left>mdi-clock-outline</v-icon>
                  <span class="font-weight-regular caption">
                    Turno abierto:
                    {{
                      $filters.datetime($store.getters.turn.opening_date)
                    }}</span
                  >
                </div>
              </td>
            </tr>
            <tr>
              <td>
                <span class="font-weight-regular">
                  <v-icon left small>mdi-ticket-percent-outline</v-icon
                  >{{ $t("taxes") }}:
                </span>
                {{
                  $filters.currency($pos.getCurrentOrder().getTotalTaxes(), 0)
                }}
              </td>
              <td>
                <span
                  class="font-weight-regular"
                  v-if="
                    $pos.getCurrentOrder().getPriceTip() > 0 ||
                    $pos.getCurrentOrder().getPriceDomicile() > 0
                  "
                >
                  <v-icon left small>mdi-cash</v-icon>{{ $t("subtotal") }}:
                </span>
                <span class="font-weight-regular" v-else>
                  <v-icon left small>mdi-cash-plus</v-icon>{{ $t("total") }}:
                </span>
                {{
                  $filters.currency($pos.getCurrentOrder().getTotalToPay(), 0)
                }}
              </td>
            </tr>

            <tr
              v-if="
                $pos.getCurrentOrder().getPriceTip() > 0 ||
                $pos.getCurrentOrder().getPriceDomicile() > 0
              "
            >
              <td :key="'tip_' + $store.getters.orderChanged">
                <div >
                  <template v-if="$pos.getCurrentOrder().getPriceTip() > 0">
                    <span class="font-weight-regular me-1"
                    ><v-icon small left>mdi-hand-heart-outline</v-icon
                    >{{ $t("tip") }}:</span
                  >
                  <v-chip small
                    color="green lighten-4"
                    class="font-weight-regular black--text green--text text--darken-1 mr-2"
                  >
                    {{
                      $filters.currency($pos.getCurrentOrder().getPriceTip(), 0)
                    }}
                    <v-icon small @click="modalTip = true" right
                      >mdi-pencil</v-icon
                    >
                  </v-chip>

                  </template>

                  <template v-if="$pos.getCurrentOrder().getPriceDomicile() > 0">
                    <span class="font-weight-regular me-1"
                    ><v-icon small left>mdi-moped</v-icon
                    >{{ $t("delivery") }}:</span
                  >
                  <v-chip small
                    color="green lighten-4"
                    class="font-weight-regular black--text green--text text--darken-1"
                  >
                    {{
                      $filters.currency($pos.getCurrentOrder().getPriceDomicile(), 0)
                    }}
                  </v-chip>

                  </template>
                  
                </div>
              </td>
              <td>
                <span class="font-weight-regular"
                  ><v-icon small left>mdi-cash-plus</v-icon
                  >{{ $t("total") }}:</span
                >
                {{
                  $filters.currency(
                    $pos.getCurrentOrder().getPriceTotalWithAdds(),
                    0
                  )
                }}
              </td>
            </tr>
          </tbody>
        </v-simple-table>
      <!--  pay {{ $filters.currency($pos.getCurrentOrder().total_to_pay) }}
        base {{ $filters.currency($pos.getCurrentOrder().total_taxes) }}
        subtotal {{ $filters.currency($pos.getCurrentOrder().subtotal) }}
        taxes {{ $filters.currency($pos.getCurrentOrder().total_taxes) }}-->
      </v-col>
    </v-row>
    <v-row class="pa-0 ma-0" id="cart_options">
      <v-col class="pa-0 ma-0 d-flex flex-row">
        <v-btn tile color="primary" x-large @click="save()" :disabled="!$pos.getCurrentOrder().getTotalItems()">
          <v-icon class="d-lg-none d-block">mdi-content-save</v-icon>
          <v-icon left class="d-none d-lg-block">mdi-content-save</v-icon>
          <span class="d-lg-block d-none">{{ $t("save") }}</span>
        </v-btn>
        <v-btn
          class="flex-grow-1 d-flex justify-space-between white--text"
          @click="pay"
          color="green"
          tile
          x-large
          :disabled="$pos.getCurrentOrder().isEmpty() || !allowedPayment()"
        >
          <div class="d-flex align-center">
            <v-icon class="me-1">mdi-cash</v-icon>
            <span>{{ $t("to_pay") }}</span>
          </div>
          <div>
            <span>{{
              $filters.currency(
                $pos.getCurrentOrder().getPriceTotalWithAdds(),
                0
              )
            }}</span>
          </div>
        </v-btn>
        <!-- <v-btn class="white--text" color="green" depressed tile block x-large @click="pay"
          :disabled="$pos.getCurrentOrder().isEmpty() || !allowedPayment()">
          <v-icon left>mdi-cash</v-icon>
          {{ $t("to_pay") }} {{
            $filters.currency(
              $pos.getCurrentOrder().getPriceTotalWithAdds(),
              0
            )
          }}</v-btn>
      </v-col>
      <v-col class="pa-0 ma-0">
        <v-btn class="white--text" color="primary" depressed tile @click="save()"
          :disabled="$pos.getCurrentOrder().isEmpty()">
          <v-icon left>mdi-content-save</v-icon>
          {{ $t("save") }}</v-btn> -->
      </v-col>
    </v-row>
    <CartCustomer
      :dialog="modalCustomer"
      @cancel="modalCustomer = false"
      @selected="selectedCustomer"
    >
    </CartCustomer>
    <CartPayments
      :dialog="modalPayments"
      @cancel="cancelPayment"
      @cancelError="cancelErrorPayment"
      :order="$pos.getCurrentOrder()"
      :is_mobile="is_mobile"
    >
    </CartPayments>
    <CartAnnulOrder
      :dialog="anullCurrentOrder"
      :order="$pos.getCurrentOrder()"
      :message="$t('really_cancel_order')"
      @close="anullCurrentOrder = false"
      @annul="annulOrder($event)"
    ></CartAnnulOrder>
    <CartNote
      :dialog="modalNote"
      :order="$pos.getCurrentOrder()"
      @cancel="modalNote = false"
      @close="modalNote = false"
    >
    </CartNote>
    <CartCoupon
      :dialog="modalCoupon"
      :order="$pos.getCurrentOrder()"
      @cancel="modalCoupon = false"
      @close="modalCoupon = false"
    ></CartCoupon>
    <CartTip
      :dialog="modalTip"
      :order="$pos.getCurrentOrder()"
      @cancel="modalTip = false"
      @modalSetAutomaticTip="modalSetAutomaticTip()"
      @close="modalTip = false"
    ></CartTip>

    <CartPrintRemote
      :dialog="modalPrintRemote"
      :order="$pos.getCurrentOrder()"
      @cancel="modalPrintRemote = false"
      @close="modalPrintRemote = false"
    ></CartPrintRemote>

    <div>
      <CartTablesRemote
        :dialog="modalTables"
        :order="$pos.getCurrentOrder()"
        @cancel="modalTables = false"
        @close="modalTables = false"
      ></CartTablesRemote>
    </div>

    <ModalTryAgain ref="modalTryAgain" @try-save-order="saveOrderSeller()" />
  </v-col>
</template>




<script>
import CartSectionItem from "./CartSectionItem";
import CartCustomer from "./CartCustomer";
import CartPayments from "./CartPayments";
import CartAnnulOrder from "./CartAnnulOrder";
import CartNote from "./CartNote";
// import CartTablesRemote from "./CartTablesRemote";
import CartCoupon from "./CartCoupon";
import CartTip from "./CartTip";
import CartTablesRemote from "./CartTablesRemote";
import { isStoreActive } from "@/plugins/db-firestore";
import ModalTryAgain from "./ModalTryAgain.vue";
import CartPrintRemote from "./CartPrintRemote.vue";
export default {
  name: "CartSection",
  components: {
    CartSectionItem,
    CartCustomer,
    CartPayments,
    CartAnnulOrder,
    CartNote,
    CartPrintRemote,
    CartTablesRemote,
    CartCoupon,
    CartTip,
    ModalTryAgain,
  },
  props: ["is_mobile", "aspect_ratio"],
  data: () => ({
    tab: null,
    orders: null,
    vHeight: 67,
    order: null,
    modalCoupon: false,
    modalCustomer: false,
    modalPayments: false,
    modalNote: false,
    modalPrintRemote:false,
    modalTip: false,
    modalTables: false,
    anullCurrentOrder: false,
    employees: [],
    sellers: [],
    domiciliaries: [],
    domiciliary: null,
    seller: null,
    price_delivery: null,
    activeTip: false,
    activeInvoiceElectronic: false,
    orderType: "is_on_table",
  }),
  computed: {
    orderTabClasses() {
      return this.$pos.getCurrentOrder().data_customer
        ? "green--text green lighten-5"
        : "grey--text";
    },
    orderTabColor() {
      return this.$pos.getCurrentOrder().data_customer
        ? "green"
        : "grey darken-1";
    },
    tipTabClasses() {
      return this.activeTip ? "green--text green lighten-5" : "grey--text";
    },
    tipTabColor() {
      return this.activeTip ? "green" : "grey darken-1";
    },
    couponTabClasses() {
      return this.$store.getters.getGeneralValue(
        "gen_sales_activate_coupons"
      ) && this.haveCoupon()
        ? "green--text green lighten-5"
        : "grey--text";
    },
    couponTabColor() {
      return this.$store.getters.getGeneralValue(
        "gen_sales_activate_coupons"
      ) && this.haveCoupon()
        ? "green"
        : "grey darken-1";
    },
    invoiceTabClasses() {
      return this.activeInvoiceElectronic
        ? "green--text green lighten-5"
        : "grey--text";
    },
    invoiceTabColor() {
      return this.activeInvoiceElectronic ? "green" : "grey darken-1";
    },
  },
  watch: {
    "$store.getters.orderChanged": function () {
      //this.order = this.$pos.getCurrentOrder();
      //console.log("order changed .................................");
      this.$set(this, "order", this.$pos.getCurrentOrder());
      this.setDefaultsOnChargeOrder();
    },
  },
  //props: ['order'],
  mounted() {
    this.order = this.$pos.getCurrentOrder();
    setTimeout(() => {
      this.setDefaultsOnChargeOrder();
      this.getEmployees();

      /* */
    }, 200);
  },
  methods: {
    getPendingOrder(id) {
      this.$http.get("api/v1/orders-pending", { id: id }).then((response) => {
        //this.orders = response.items;
        this.$db.updateOrders(response.items);
      });
    },
    allowedPayment() {
      let order = this.$pos.getCurrentOrder();
      if (order.is_electronic_invoice) {
        if (!order.data_customer) {
          return false;
        }
      }
      return true;
    },
    activateCoupons() {
      return true;
    },
    activateDomicilies() {
      return false;
    },
    pay() {
      if (this.order.is_domicile) {
        if (
          !this.order.data_customer ||
          !this.order.data_customer["first_name"] ||
          !this.order.data_customer["phone"] ||
          !this.order.data_customer["address"]
        ) {
          this.modalCustomer = true;
          return;
        }
      }
      this.modalPayments = true;
    },
    annulOrder(order) {
      this.anullCurrentOrder = false;
      this.createNewOrder();
    },
    async getValueActivePrintRemote() {
      let value = false;
      let installation = this.$store.getters.installation;
      if (installation) {
        const cashRegister = await this.$db.getCashRegister(
          installation.cash_id
        );
        if (cashRegister) {
          if (cashRegister.config) {
            if (cashRegister.config.pos_active_print_remote) {
              value = cashRegister.config.pos_active_print_remote;
            }
          }
        }
      }
      return value;
    },
    haveCoupon() {
      if (this.order.getCoupon()) {
        return true;
      }
      return false;
    },
    cancelPayment(params) {
      if (params) {
        if (params.orderfinalized) {
          this.createNewOrder();
          this.activeTip = false;
          this.activeInvoiceElectronic = false;
        } else {
          this.$pos.deleteAllPayments();
        }
      }
      this.modalPayments = false;
    },
    cancelErrorPayment() {
      this.$pos.deleteAllPayments();
      this.modalPayments = false;
    },
    openAnnulOrder() {
      this.anullCurrentOrder = true;
    },
    changeOrderType(type) {
      //ver orden en que estan los botones en el v-btn-toggle
      if (this.orderType == "is_direct") {
        //en_tienda
        this.setVHeight();
        this.$pos.setIsDomicile(this.order, false);
        this.$pos.setIsOnTable(this.order, false);
        this.$pos.setNameTable(this.order, null);
        this.$pos.setStorePickup(this.order, true);
        this.$pos.setPriceDomicile(this.order, 0);
        this.$pos.setDomiciliary(null);
      }
      if (this.orderType == "is_on_table") {
        this.setVHeight();
        // this.modalTables = true;
        this.$pos.setIsOnTable(this.order, true);
        this.$pos.setIsDomicile(this.order, false);
        this.$pos.setStorePickup(this.order, false);
        this.$pos.setPriceDomicile(this.order, 0);
        this.$pos.setDomiciliary(null);
      }
      if (this.orderType == "is_domicile") {
        //domicilios
        this.setVHeight();
        this.$pos.setIsDomicile(this.order, true);
        this.$pos.setIsOnTable(this.order, false);
        this.$pos.setNameTable(this.order, null);
        this.$pos.setStorePickup(this.order, false);
      }
    },
    electronicInvoiceHint() {
      return this.$t("invoice_electronic");
    },
    async saveOrderSeller() {
      // naren
      // validar con isStoreActive si la tienda esta activa
      // si no esta activa le salga una modal indicando que el pos no esta recibiendo las ordenes
      // y un boton de actualizar
      let installation = this.$store.getters.installation;
      //descomentar si es necesario
     /* let active = await isStoreActive(installation.subsidiary_id, 1);
      if (!active) {
        this.$refs.modalTryAgain.renderItem();
        return;
      }*/
      this.$pos.setOrderStatus(this.$pos.getCurrentOrder(), "pending");
      let name_table = this.$pos.getCurrentOrder().getNameTable();
      if (name_table) {
        let table = await this.$db.getTableByName(name_table);
        await this.$db.updateTableBusy(table, true);
      } else {
        this.modalTables = true;
        //alert('Debe seleccionar una mesa')
        return;
      }

      // naren en el api de guardar la orden se debe validar si from_seller es true, en este caso
      // se debe notificar por firebase desde laravel el evento: id de la orden el time, y st=draft
      // y tambien enviar una notificacion push al mesero
      let response = await this.$pos.saveOrderSeller(
        this.$pos.getCurrentOrder(),
        this.$http
      );
 
      this.createNewOrder();
    },
    createNewOrder() {
      this.$pos.createOrder();
      this.$store.dispatch("createdOrder");
    },
    async save() {
      this.$pos.setOrderStatus(this.$pos.getCurrentOrder(), "received");
      if (this.$pos.getCurrentOrder().getIsOnTable()) {
        let name_table = this.$pos.getCurrentOrder().getNameTable();
        if (name_table) {
          let table = await this.$db.getTableByName(name_table);
          await this.$db.updateTableBusy(table, true);
        }
      }
      this.$pos.saveOrder(this.$pos.getCurrentOrder(), this.$http);

      this.createNewOrder();
    },
    selectedCustomer(customer) {
      this.$pos.setCustomer(customer);
      this.modalCustomer = false;
    },
    deleteItem(itemLine) {
      //si la orden ya se imprimio debe escribir un motivo, se guarda el motivo en la orden
      this.$pos.deleteItem(itemLine);
      this.$pos.setPriceTip(this.$pos.getCurrentOrder(), null);
    },
    removeClient() {
      this.$pos.setCustomer(null);
      this.modalCustomer = false;
    },
    noteOrder() {
      this.modalNote = true;
    },
    openModalCoupon() {
      this.modalCoupon = true;
    },
    openModalTables() {
      this.modalTables = true;
    },
    openModalTip() {
      this.modalTip = true;
    },
    changeSeller(evt) {
      this.$pos.setSeller(evt);
    },
    changeDomiciliary(evt) {
      this.$pos.setDomiciliary(evt);
    },
    changePriceDelivery(evt) {
      if (evt) {
        this.$pos.setPriceDomicile(this.order, parseInt(evt));
      } else {
        this.$pos.setPriceDomicile(this.order, 0);
      }
    },
    changeInvoiceElectronic() {
      if (this.activeInvoiceElectronic) {
        let order = this.$pos.getCurrentOrder();
        if (!order.data_customer) {
          this.modalCustomer = true;
        }
      }

      this.$pos.setIsElectronicInvoice(
        this.order,
        this.activeInvoiceElectronic
      );
    },
    changeTip() {
      this.$pos.setActiveTip(this.$pos.getCurrentOrder(), this.activeTip);
      if (this.activeTip) {
        this.$pos.setPriceTip(this.order, null);
      } else {
        this.$pos.setPriceTip(this.order, 0);
      }
      this.setVHeight();
    },
    printRemote() {
      this.modalPrintRemote = true;      
    },
    printScreen() {
      this.$pos.printReceiptScreen(this.order);
    },
    setColorNote() {
      if (this.order.getNote()) {
        return "primary";
      }
    },
    setDefaultsOnChargeOrder() {
      if (this.order.getIsOnTable()) {
        this.orderType = "is_on_table";
      } else if (this.order.getIsDomicile()) {
        this.orderType = "is_domicile";
      } else {
        this.orderType = "is_direct";
      }

      if (this.order.getPriceTip() > 0) {
        this.activeTip = true;
      }

      if (this.order.getIsElectronicInvoice()) {
        this.activeInvoiceElectronic = true;
      }

      if (this.order.getBusPersonDomiciliaryId()) {
        this.domiciliary = this.order.getDataDomiciliary();
      } else {
        this.domiciliary = null;
      }

      if (this.order.getBusPersonSellerId()) {
        this.seller = this.order.getDataSeller();
      } else {
        this.seller = null;
      }

      if (this.order.getPriceDomicile() && this.order.getPriceDomicile() > 0) {
        this.price_delivery = this.order.getPriceDomicile();
      } else{
        this.price_delivery = null;
      }
      this.setVHeight();
    },
    getHeight() {
      let th = 58;
      if (this.is_mobile) {
        // return "";
        // th = 15;
      }
      if (this.$refs.optionsOrder) {
        th += this.$refs.optionsOrder.clientHeight;
        th += this.$refs.totalsSection.clientHeight;
        return "overflow-y:auto;height: calc(100vh - " + th + "px);";
      } else {
        return "overflow-y:auto;height: 25vh;";
      }
    },
    setVHeight() {},
    async getEmployees() {
      const sellers = await this.$db.getEmployeesByType("seller");
      this.sellers = sellers;
      const domiciliaries = await this.$db.getEmployeesByType("deliverer");
      this.domiciliaries = domiciliaries;
    },
    modalSetAutomaticTip($event) {},
    setColspanMobile(value) {
      if (this.is_mobile) {
        return value;
      }
      return 1;
    },
    setColAspect() {
      const aspect = ["5:4", "4:3", "16:9", "16:10"];
      if (this.aspect_ratio) {
        if (this.aspect_ratio.aspect_ratio) {
          if (this.inArray(this.aspect_ratio.aspect_ratio, aspect)) {
            return 5;
          }
        }
      }
      return 4;
    },
    inArray(needle, haystack) {
      var length = haystack.length;
      for (var i = 0; i < length; i++) {
        if (haystack[i] == needle) return true;
      }
      return false;
    },
  },
};
</script>
<style scoped>
.mobile_height {
  height: 100vh !important;
}

.theme--light.v-btn.v-btn--disabled.v-btn--has-bg {
  background-color: rgba(0, 0, 0, 0.05) !important;
}

.theme--light.v-btn-toggle:not(.v-btn-toggle--group) {
  background: none;
}

.v-btn-toggle > .v-btn.v-btn {
  border-style: none;
}

.v-item--active {
  border: none !important;
}

.orderType .v-item--active {
  background: #e3f2fd !important;
  color: #24a9e1 !important;
  /* background: #E8F5E9 !important; */
  /* color: #4CAF50 !important; */
}

.orderType .v-item--active .v-icon {
  color: #24a9e1 !important;
  /* color: #4CAF50 !important; */
}

.v-btn:before {
  background-color: #e4e4e4 !important;
}
</style>

<style>
@media only screen and (max-width: 981px) {
  #totals_section {
    bottom: 5ch;
  }

  #cart_options {
    bottom: 0;
    background: #fafafa;
  }

  #totals_section,
  #cart_options {
    position: fixed;
    z-index: 100;
    width: 100%;
  }
}

.desktop-height {
  height: calc(100vh - 70px) !important;
}

.mobile-height {
  height: calc(100vh - 14ch) !important;
}

.cart_options .v-select > .v-input__control > .v-input__slot label {
  color: rgba(0, 0, 0, 1);
  font-size: 0.875rem !important;
}

.cart_options .v-text-field > .v-input__control > .v-input__slot label {
  color: rgba(0, 0, 0, 1);
  font-size: 0.875rem !important;
}

.cart_options .v-input--switch > .v-input__control > .v-input__slot label {
  color: rgba(0, 0, 0, 1);
  font-size: 0.875rem !important;
  font-weight: 400 !important;
}

.theme--light.v-data-table
  > .v-data-table__wrapper
  > table
  > tbody
  > tr:not(:last-child)
  > td:not(.v-data-table__mobile-row),
.theme--light.v-data-table
  > .v-data-table__wrapper
  > table
  > tbody
  > tr:not(:last-child)
  > th:not(.v-data-table__mobile-row) {
  border-bottom: none;
}

.cart_coupon_fix span {
  top: -5px;
}

.border-left {
  border-left: 1px solid #e2e4e8;
}
</style>