<template>
  <div>
    <v-dialog v-model="open" persistent fullscreen>
      <v-container fill-height fluid class="bg_install">
        <v-row>
          <v-col sm="8" md="6" lg="4" offset-sm="2" offset-md="3" offset-lg="4">

            <v-row>
              <v-col sm="6" md="6" lg="6" offset-sm="3" offset-md="3" offset-lg="3">
                <v-alert color="transparent" dark>
                  <img src="/assets/images/brand/white-horizontal-logo.svg" width="100%">
                </v-alert>
              </v-col>
            </v-row>

            <ValidationObserver v-slot="{ invalid }" ref="form">
              <v-card class="auth-glass pa-5">
                <v-row>
                  <v-col class="d-flex justify-end pa-5"><v-btn outlined color="success">
                      <v-icon>mdi-play</v-icon> Tutorial
                    </v-btn></v-col>
                </v-row>
                <v-card-title class="text-center">
                  <div class="div mx-auto">
                    <v-icon class="d-block mb-3" size="50" color="success">mdi-source-branch-sync</v-icon>
                    <h2 class="success--text mx-auto">{{ $t("Instalar POS") }}</h2>
                  </div>
                </v-card-title>

                <v-row class="pa-4" :key="key">
                  <v-col cols="12" v-if="!agent">
                    <p class="text-center">
                      <strong>¡Atención!</strong> No hemos detectado el agente de impresión. Para mejorar el correcto
                      funcionamiento, descarga e instala el agente.
                    </p>

                    <div class="row text-center">
                      <div class="col-lg-12 pb-0">
                        <h4 class="grey--text text--darken-5 font-weight-regular">{{ $t("download_here") }}</h4>
                      </div>
                      <div class="col-lg-12">
                        <v-btn elevation="0" color="primary"><a href="localhost:8000/agente-impresion.exe"
                            class="white--text" target="_blank"><v-icon class="me-2">mdi-microsoft-windows</v-icon>{{
                              $t("for_windows") }}</a></v-btn>
                      </div>
                    </div>
                  </v-col>

                  <v-col cols="12" v-if="backup">
                    <strong>Se ha detectado una copia del sistema, ¿Desea restaurarla?</strong>
                    <div><v-btn @click="restore()" :loading="loading" :disabled="loading">Restaurar Backup</v-btn></div>
                  </v-col>
                  <v-col cols="12">
                    <ng-select-options v-model="item.bus_subsidiary_id" label="Sucursal" :rules="{ required: true }"
                      :items="subsidiaries" @input="changeSubsidiary" :filled="false" :dense="false"></ng-select-options>
                    <ng-select-options v-model="item.bill_cash_register_id" label="Caja" :rules="{ required: true }"
                      :items="cashResgisters" :filled="false" :dense="false"></ng-select-options>
                  </v-col>
                </v-row>

                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn text @click="$emit('cancel')" color="primary" outlined>
                    {{ $t("Cancel") }}
                  </v-btn>
                  <v-btn color="success" elevation="0" @click="install" :loading="loading" :disabled="invalid || loading">
                    {{ $t("install") }}
                  </v-btn>
                </v-card-actions>
              </v-card>
            </ValidationObserver>
          </v-col>
        </v-row>
      </v-container>
    </v-dialog>
  </div>
</template>
<style>
a {
  text-decoration: none;
}

.bg_install {
  height: 100% !important;
  background-color: #F7F7F9 !important;
  background-image: linear-gradient(to bottom, rgba(255, 255, 255, 0.52), rgba(0, 0, 0, 0.75)), url("/assets/background-2-min.jpg") !important;
  background-size: cover !important;
  background-position: center !important;
  background-repeat: no-repeat !important;
}
</style>
<script>
import localAgent from '@/plugins/local-agent.js'
import appUtil from "@/plugins/app-utils.js";

export default {
  name: "Install",
  components: {},
  props: ["open"],
  data: () => ({
    dialog: true,
    key: 0,
    subsidiaries: [],
    cashResgisters: [],
    item: { bus_subsidiary_id: null, bill_cash_register_id: null },
    agent: false,
    backup: false,
    loading: false
  }),
  mounted() {
    this.loadSubsidiaries();
    this.validateAgent();

  },
  methods: {
    changeLocationHref(){
      appUtil.changeLocationHref()
    },
    async restore() {
      this.loading = true
      let ok = await localAgent.restore(this.$db)

      setTimeout(() => {
        this.loading = false
        this.changeLocationHref();
      }, 5000)

    },
    async validateAgent() {

      try {
        let useAgent = false;
        let installation = this.$store.getters.installation;
        if (installation) {
          const cashRegister = await this.$db.getCashRegister(
            installation.cash_id
          );
          if(cashRegister){
            if(cashRegister.config){
              if(cashRegister.config.pos_active_print_agent){
                useAgent = true;
                console.log('use agent');
              }
            }
          }
        }	
        if(useAgent){
          // Realizar una solicitud GET a la API en localhost:5777/is-run
          const response = await localAgent.validateAgent();
          // Comprobar el código de estado de la respuesta (200 significa éxito)
          if (response.success == true) {
            this.agent = true; // La API está en funcionamiento
            if (response.backup) {
              this.backup = true
            }
            //this.validateBackup();
          } else {
            this.agent = false; // La API no está en funcionamiento
          }
        }
      } catch (e) {

      }

    },
    install() {
      this.$http
        .post(
          "api/v1/subsidiary/pos-install",
          this.item,
          true,
          true
        )
        .then(async (response) => {
          if (response.success) {
            await this.$db.install(response.config);

            const inst = await this.$db.getInstallation();


            setTimeout(() => {
              let txt = this.$db.serialize();
              localAgent.save(txt)
            }, 500)

            this.$emit("installed");
          }
        });
    },
    changeSubsidiary(evt) {

      // param 'installed': false
      if (evt) {
        this.$http
          .get(
            "api/v1/cash-register",
            {
              subsidiary: [{ id: evt.id, }],
              _paginate: { page: 1, max_rows: 100 },
              _order: [{ field: "name", direction: "asc" }],
            },
            true,
            true
          )
          .then((response) => {
            if (response.success) {
              this.cashResgisters = response.items;
              //this.key++;
            }
          });
      } else {
        this.cashResgisters = [];
      }
    },

    loadSubsidiaries() {
      this.$http.get('api/v1/lists/subsidiary', { '_order': [{ field: "name", direction: "asc" }] })

        .then((response) => {
          if (response.success) {
            this.subsidiaries = response.items;
            this.key++;
          }
        });
    },
  },
};
</script>
